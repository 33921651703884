import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainPage from './pages/MainPage';
import './css/MatrixBackground.css';

function App() {
    React.useEffect(() => {
        const createFallingText = () => {
            const container = document.querySelector('.logo-background');
            if (container) {
                const textElement = document.createElement('div');
                textElement.className = 'falling-text';
                textElement.innerText = 'THE BTICOIN CHIP';
                textElement.style.left = `${Math.random() * 100}%`;
                textElement.style.animationDuration = `${2 + Math.random() * 3}s`;
                textElement.style.writingMode = 'vertical-rl';
                textElement.style.transform = 'rotate(180deg)';
                container.appendChild(textElement);

                setTimeout(() => {
                    container.removeChild(textElement);
                }, 5000);
            }
        };

        const interval = setInterval(createFallingText, 500);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="bg-darkGray min-h-10 h-70">

            {/* Logo Image Section */}
            <div className="flex mb-5 items-center h-[30vh] md:h-[30vh] lg:h-[25vh] min-h-40 justify-center logo-background border-b-2 border-solid border-bitcoinOrange" style={{ backgroundImage: `url('/images/background.svg')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="flex items-center justify-center">
                    <img className="w-1/2 mt-5 mb-5" src="/images/btcchip.svg" alt="The Bitcoin Chip" />
                </div>
            </div>



            {/* Main Content Routing */}
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MainPage />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;