import React from 'react';

const About = () => {
    return (
        <section className="flex flex-col items-center justify-center h-[65vh] md:h-[65vh] lg:h-[72vh] bg-darkGray relative px-4 md:px-8">
            <img src="/images/heading.svg" alt="THE�BITCOIN�CHIP" className="mb-10 w-full max-w-lg md:max-w-2xl lg:max-w-3xl animate-bounce-slow" />
            <h2 className="font-dpcomic text-4xl md:text-5xl text-white text-center mb-8 md:mb-10">
                21,000,000 Supply
            </h2>
            <h2 className="font-dpcomic text-4xl md:text-5xl text-white text-center mb-8 md:mb-10">
                0% Premine
            </h2>
            <h2 className="font-dpcomic text-4xl md:text-5xl text-white text-center mb-8 md:mb-10">
                100% Free and Fair
            </h2>

            <div className="flex mt-10 space-x-4 md:space-x-8">
                <a href="https://luminex.io/rune/THE%E2%80%A2BITCOIN%E2%80%A2CHIP" target="_blank" rel="noopener noreferrer" className="inline-block z-10">
                    <img src="/icons/luminex.svg" alt="Luminex Icon" className="opacity-100 transition-opacity duration-500 w-12 md:w-14 hover:scale-110 transform transition-transform duration-300 pointer-events-auto" />
                </a>
                <a href="https://magiceden.io/runes/THE%E2%80%A2BITCOIN%E2%80%A2CHIP" target="_blank" rel="noopener noreferrer" className="inline-block z-10">
                    <img src="/icons/me.svg" alt="ME Icon" className="opacity-100 transition-opacity duration-500 w-12 md:w-14 hover:scale-110 transform transition-transform duration-300 pointer-events-auto" />
                </a>
                <a href="https://x.com/thebitcoinchip" target="_blank" rel="noopener noreferrer" className="inline-block z-10">
                    <img src="/icons/x.svg" alt="X Icon" className="opacity-100 transition-opacity duration-500 w-12 md:w-14 hover:scale-110 transform transition-transform duration-300 pointer-events-auto" />
                </a>
            </div>
        </section>
    );
};

export default About;
