import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const Footer = () => {
    const { ref, inView } = useInView({ triggerOnce: true });

    useEffect(() => {
        if (inView) {
            const icons = document.querySelectorAll('.footer-icon');
            icons.forEach((icon, index) => {
                setTimeout(() => {
                    icon.classList.remove('opacity-0');
                }, index * 150);
            });

            // Add fade-in effect for the cone
            const cone = document.querySelector('.fade-in-cone');
            if (cone) {
                cone.classList.remove('opacity-0');
                cone.classList.add('fade-in-animation');
            }
        }
    }, [inView]);

    return (
        <section ref={ref} className="flex flex-col items-center justify-center h-[40vh] md:h-[50vh] lg:h-[30vh] w-full bg-darkGray relative">
            <img src="/icons/h.svg" alt="Signature" className="mt-10 footer-icon opacity-0 transition-opacity duration-500 w-12 " />
            <div className="absolute bottom-0 transform -translate-x-1/2 w-[100%] h-[100vh] bg-radial-cone opacity-0 fade-in-cone"></div>
        </section>
    );
};

export default Footer;