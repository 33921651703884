import React from 'react';
import About from '../components/About';
import Footer from '../components/Footer';

const MainPage = () => {
    return (
        <div className="bg-darkGray min-h-screen lg:h-[72vh]"> {/* Apply background color and full screen height */}
            <About />
            <Footer />
        </div>
    );
};

export default MainPage;
